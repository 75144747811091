<template lang="pug">
  v-container(fluid)
    v-row
      v-col(cols="12" sm="8")
        v-card(:loading="isLoadingAlarms")
          v-card-title {{ $tc('alarms.alarm', 2) }}
          v-card-text(v-if="!hasAlarms")
            em {{ $t('alarms.none-found') }}
          v-expansion-panels(flat v-if="hasAlarms")
            v-expansion-panel(v-for="(alarm, index) in getAlarms" :key="alarm.id")
              v-expansion-panel-header
                | {{ alarm.name }}
                v-spacer
                div.text-right
                  v-chip.mr-2(
                    small
                    label
                  )
                    v-icon(small left) mdi-bell-outline
                    | {{ alarmTypes[alarm.alarmType].text }}
                    template(
                      v-if="alarm.alarmType === AlarmTypes.LowHigh"
                    )
                      | &nbsp;
                      strong {{ alarm.alarmLow }}
                      | &nbsp;{{ $t('to') }}&nbsp;
                      strong {{ alarm.alarmHigh }}
                    template(
                      v-if="alarm.alarmType === AlarmTypes.Boolean"
                    )
                      v-icon(v-if="alarm.boolean" small right color="success") mdi-check-circle
                      v-icon(v-else small right color="primary") mdi-close-circle
              v-expansion-panel-content
                v-chip(label small v-if="alarm.delay")
                  v-icon(left small) mdi-clock-alert-outline
                  | {{ alarm.delay }}s
                h2.caption.mt-2 {{ $tc('assets.asset', 2) }}
                v-chip-group(column)
                  v-chip(
                    v-for="asset in getAssetsByIds(alarm.assetIds)"
                    :key="`${alarm.id}-${asset.id}`"
                    small
                    link
                    @click="$router.push({ name: 'asset', params: { id: asset.id } })"
                  )
                    v-icon(
                      left
                      small
                    ) mdi-router-wireless
                    | {{ asset.name }}
                    v-icon(
                      v-if="asset.isAlarmWarningActive(alarm)"
                      color="warning"
                      right
                      small
                    ) mdi-bell-outline
                    v-icon(
                      v-if="asset.isAlarmErrorActive(alarm)"
                      color="error"
                      right
                      small
                    ) mdi-bell-alert-outline
                h2.caption.mt-2 {{ $tc('sensors.sensor', 2) }}
                v-chip-group(column)
                  v-chip(
                    v-for="sensorType in sensorTypesForAlarm(alarm)"
                    :key="`${alarm.id}-${sensorType.id}`"
                    small
                  ) {{ sensorType.name }}
                v-card-actions
                  v-spacer
                  v-btn(
                    @click="alarmsManager.deleteObject(alarm)"
                    text
                    left
                    small
                    color="error"
                  )
                    v-icon(left) mdi-delete
                    | {{ $t('delete') }}
                  v-btn(
                    @click="alarmsManager.editObject(alarm)"
                    text
                    left
                    small
                    color="primary"
                  )
                    v-icon(left) mdi-pencil-outline
                    | {{ $t('edit') }}
              v-divider(v-if="index !== (getAlarms.length - 1)")
          v-divider
          v-card-actions
            v-spacer
            ManageObject(
              ref="alarmsManager"
              :objectText="$tc('alarms.alarm', 1)"
              objectTitleKey="name"
              :defaultObject="defaultAlarm"
              actionAdd="ADD_ALARM"
              actionUpdate="UPDATE_ALARM"
              actionDelete="DELETE_ALARM"
            )
              template(
                v-slot:form="{ edittingObject }"
              )
                v-row
                  v-col(cols="12")
                    v-card
                      v-card-text
                        v-text-field(
                          v-model="edittingObject.name"
                          :label="$t('name')"
                          :rules="rules"
                          required
                        )
                        v-select(
                          v-model="edittingObject.alarmType"
                          :items="alarmTypesArray"
                          item-text="text"
                          item-value="key"
                          :label="$t('alarms.type')"
                          :rules="rules"
                          @change="updateAlarmModel(edittingObject)"
                        )
                      v-divider
                      v-card-text
                        v-expand-transition
                          div(
                            v-if="edittingObject.alarmType === AlarmTypes.LowHigh"
                          )
                            v-text-field(
                              v-model.number="edittingObject.alarmLow"
                              type="number"
                              :label="$t('alarms.value.less')"
                              :rules="rules"
                              required
                            )
                            //- v-text-field(
                            //-   v-model.number="edittingObject.clearLow"
                            //-   type="number"
                            //-   label="Alarm clearen bij herstelde lage waarde van"
                            //-   :rules="rules"
                            //-   required
                            //- )
                            v-text-field(
                              v-model.number="edittingObject.alarmHigh"
                              type="number"
                              :label="$t('alarms.value.greater')"
                              :rules="rules"
                              required
                            )
                            //- v-text-field(
                            //-   v-model.number="edittingObject.clearHigh"
                            //-   type="number"
                            //-   label="Alarm clearen bij herstelde hoge waarde van"
                            //-   :rules="rules"
                            //-   required
                            //- )
                          //- div(
                          //-   v-if="edittingObject.alarmType === 'alarmNoData'"
                          //- )
                          //-   v-text-field(
                          //-     v-model.number="edittingObject.duration"
                          //-     type="number"
                          //-     label="Alarm bij geen data langer dan"
                          //-     :rules="rules"
                          //-     required
                          //-     suffix="seconden"
                          //-   )
                          div(
                            v-else-if="edittingObject.alarmType === AlarmTypes.Boolean"
                          )
                            v-switch(
                              v-model="edittingObject.boolean"
                              :hint="$t('alarms.value.boolean')"
                              persistent-hint
                            )
                              template(v-slot:label)
                                span(v-if="edittingObject.boolean")
                                  | {{ $t('alarms.value.boolean-true') }}
                                span(v-else)
                                  | {{ $t('alarms.value.boolean-false') }}
                          div(v-else)
                            em {{ $t('alarms.select-type') }}
                      v-divider
                      v-card-text
                        v-text-field(
                          v-model.number="edittingObject.delay"
                          type="number"
                          :label="$t('alarms.delays.delay')"
                          :hint="$t('alarms.delays.explain')"
                          :rules="rules"
                          required
                          persistent-hint
                          :suffix="$tc('date.second', 2).toLowerCase()"
                        )
                v-row
                  v-col(cols="12")
                    v-card
                      v-card-text
                        v-autocomplete(
                          v-model="edittingObject.assetIds"
                          :items="assetsInAzure"
                          :label="$t('alarms.applicable.assets')"
                          item-text="name"
                          item-value="id"
                          small-chips
                          clearable
                          deletable-chips
                          multiple
                          :rules="[notEmptyRule]"
                        )
                        v-autocomplete(
                          v-model="edittingObject.sensorTypeIds"
                          :items="filteredSensorTypes(edittingObject.assetIds)"
                          :label="$t('alarms.applicable.sensors')"
                          item-text="name"
                          item-value="id"
                          small-chips
                          clearable
                          deletable-chips
                          multiple
                          :rules="[notEmptyRule]"
                          :no-data-text="$t('alarms.select-asset')"
                        )
                          template(v-slot:item="data")
                            v-list-item-action
                              v-checkbox(:input-value="data.attrs.inputValue")
                            v-list-item-content
                              v-list-item-title {{ data.item.name }}
                                strong.ml-2(v-html="data.item.unit")
                              v-list-item-subtitle {{ data.item.meta.source }}
                        v-autocomplete(
                          v-model="edittingObject.personalNotificationIds"
                          :items="getPersonalNotifications"
                          :label="$t('alarms.send-notifications')"
                          item-text="name"
                          item-value="id"
                          small-chips
                          clearable
                          deletable-chips
                          multiple
                        )
                v-row
                  v-col(cols="12")
                    GroupsAccess(
                      v-model="edittingObject.groups"
                    )
      v-col(cols="12" sm="4")
        v-card(:loading="isLoadingPersonalNotifications")
          v-card-title {{ $tc('notifications.notification', 2) }}
          v-card-text {{ $t('notifications.explain') }}
          DesktopNotifications(show-always)
          v-subheader {{ $t('notifications.personal') }}
          v-card-text(v-if="!hasPersonalNotifications")
            em {{ $t('notifications.none-found') }}
          v-list(subheader v-else)
            template(
              v-for="personalNotification in getPersonalNotifications"
            )
              v-list-item(
                :key="`list-item-${personalNotification.id}`"
              )
                v-list-item-avatar(color="white")
                  v-icon(color="primary")
                    template(v-if="personalNotification.type") mdi-mail
                    template(v-else) mdi-bell
                v-list-item-content
                  v-list-item-title {{ personalNotification.name }}
                v-list-item-action
                  v-menu(
                    bottom
                    left
                  )
                    template(v-slot:activator="{ on, attrs }")
                      v-btn(
                        icon
                        v-bind="attrs"
                        v-on="on"
                      )
                        v-icon mdi-dots-vertical
                    v-list
                      v-list-item(
                        @click="personalNotificationsManager.editObject(personalNotification)"
                      )
                        v-list-item-icon
                          v-icon mdi-pencil
                        v-list-item-content
                          v-list-item-title
                            | {{ $t('item.edit', { item: personalNotification.name }) }}
                      v-list-item(
                        @click="personalNotificationsManager.deleteObject(personalNotification)"
                      )
                        v-list-item-icon
                          v-icon mdi-delete
                        v-list-item-content
                          v-list-item-title
                            | {{ $t('item.delete', { item: personalNotification.name }) }}
                      v-list-item(
                        @click="copyText(personalNotification.id)"
                      )
                        v-list-item-icon
                          v-icon mdi-identifier
                        v-list-item-content
                          v-list-item-title
                            | {{ $t('item.copy', { item: 'ID' }) }}
              v-divider(
                :key="`divider-${personalNotification.id}`"
              )
          v-card-actions
            v-spacer
            ManageObject(
              ref="personalNotificationsManager"
              :objectText="$tc('notifications.notification', 1)"
              objectTitleKey="name"
              :defaultObject="defaultPersonalNotification"
              actionAdd="ADD_PERSONAL_NOTIFICATION"
              actionUpdate="UPDATE_PERSONAL_NOTIFICATION"
              actionDelete="DELETE_PERSONAL_NOTIFICATION"
            )
              template(
                v-slot:form="{ edittingObject }"
              )
                v-text-field(
                  v-model="edittingObject.name"
                  :label="$t('name')"
                  :rules="rules"
                  required
                )
                v-combobox(
                  v-model="edittingObject.mailAddresses"
                  :label="$tc('mail-address', 1)"
                  :items="[]"
                  required
                  multiple
                  chips
                  deletable-chips
                  :delimiters="[' ']"
                )
                v-expand-transition
                  v-btn(
                    v-if="!edittingObject.mailAddresses.includes(getCurrentUser.mail)"
                    small
                    @click="edittingObject.mailAddresses.push(getCurrentUser.mail)"
                  )
                    v-icon(left) mdi-email-plus-outline
                    | {{ $t('item.add', { item: getCurrentUser.mail }) }}
                GroupsAccess(
                  v-model="edittingObject.groups"
                )
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import ManageObject from '@/components/misc/ManageObject.vue';
import GroupsAccess from '@/components/form/GroupsAccess.vue';
import DesktopNotifications from '@/components/notification/DesktopNotifications.vue';
import { AlarmTypes } from '@/models/alarm';

export default Vue.extend({
  name: 'Alarms',
  components: {
    ManageObject,
    DesktopNotifications,
    GroupsAccess,
  },

  data() {
    return {
      step: 1,
      alarmEditDialog: false,
      validAlarm: true,
      defaultAlarm: {
        name: '',
        alarmType: '',
        sensorTypeIds: '',
        assetIds: [],
        groups: [],
        personalNotificationIds: [],
        delay: 0,
      },
      defaultPersonalNotification: {
        name: '',
        mailAddresses: [],
        groups: [],
      },
      AlarmTypes,
      alarmTypes: {
        [AlarmTypes.LowHigh]: {
          text: this.$t('threshold'),
          fields: {
            alarmLow: null,
            alarmHigh: null,
          // clearLow: null,
          // clearHigh: null,
          },
        },
        [AlarmTypes.Boolean]: {
          text: this.$t('alarms.value.boolean'),
          fields: {
            boolean: true,
          // clearLow: null,
          // clearHigh: null,
          },
        },
      // alarmNoData: {
      //   text: 'Geen data voor bepaalde tijd',
      //   fields: {
      //     duration: null,
      //   },
      // },
      },
      rules: [
        (v) => !!v || v === 0 || this.$t('validation.mandatory'),
      ],
      notEmptyRule: (v) => v.length > 0 || this.$tc('validation.select-min', 1, { n: 1 }),
      deletingAlarmId: null,
      loadingAlarm: false,
      loadingDeletingAlarm: false,
      personalNotificationTypes: [{
        text: this.$t('mail'),
        value: 'mail',
      }],
    };
  },
  mounted() {
    this.$store.dispatch('GET_ALARMS');
    this.$store.dispatch('GET_PERSONAL_NOTIFICATIONS');
    this.$store.dispatch('GET_SENSOR_TYPES');
  },
  computed: {
    ...mapGetters([
      'getAssets',
      'getAsset',
      'getSensorTypes',
      'getSensorType',
      'hasAlarms',
      'getAlarms',
      'getAlarm',
      'isLoadingAlarms',
      'getCurrentUser',
      'getPersonalNotifications',
      'hasPersonalNotifications',
      'isLoadingPersonalNotifications',
    ]),
    alarmTypesArray() {
      return Object.keys(this.alarmTypes).map((alarmType) => ({
        key: alarmType,
        ...this.alarmTypes[alarmType],
      }));
    },
    assetsInAzure() {
      return this.getAssets.filter((a) => a.inAzure && a.sensors.length > 0);
    },
    alarmsManager() {
      return this.$refs.alarmsManager;
    },
    personalNotificationsManager() {
      return this.$refs.personalNotificationsManager;
    },
  },
  methods: {
    updateAlarmModel(edittingObject) {
      const newEdittingObject = edittingObject;
      Object.entries(this.alarmTypes).forEach(([typeKey, typeValue]) => {
        Object.entries(typeValue.fields)
          .forEach(([key, value]) => {
            if (edittingObject.alarmType === typeKey) {
              newEdittingObject[key] = value;
            } else {
              delete newEdittingObject[key];
            }
          });
      });
    },
    sensorTypesForAlarm(alarm) {
      return alarm.sensorTypeIds
        .map((sensorTypeId) => this.getSensorType(sensorTypeId))
        .filter((sensorType) => !!sensorType);
    },
    getAssetsByIds(assetIds) {
      return this.getAssets.filter((a) => assetIds.includes(a.id));
    },
    filteredSensorTypes(assetIds) {
      const sensorTypeIds = this.getAssetsByIds(assetIds)
        .map((a) => a.sensors.map((s) => s.sensorType.id))
        .flat();
      return this.getSensorTypes
        .filter((st) => sensorTypeIds.includes(st.id));
    },
  },
});
</script>
